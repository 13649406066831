import "../styles/index.scss";
import "./mdb_custom";
// import * as mdb from 'mdb-ui-kit';

// BOOTSTRAP CORE COMPONENTS
import Button from '../mdb/js/free/button';
import Collapse from '../mdb/js/bootstrap/mdb-prefix/collapse';
import Offcanvas from '../mdb/js/bootstrap/mdb-prefix/offcanvas';
import Alert from '../mdb/js/free/alert';
import Carousel from '../mdb/js/free/carousel';
import Modal from '../mdb/js/free/modal';
import Popover from '../mdb/js/free/popover';
import ScrollSpy from '../mdb/js/free/scrollspy';
import Tab from '../mdb/js/free/tab';
import Tooltip from '../mdb/js/free/tooltip';
import Toast from '../mdb/js/free/toast';

// MDB FREE COMPONENTS
import Input from '../mdb/js/free/input';
import Dropdown from '../mdb/js/free/dropdown';
import Ripple from '../mdb/js/free/ripple';
import Range from '../mdb/js/free/range';

// MDB PRO COMPONENTS
import Navbar from '../mdb/js/pro/navbar';
import ChipsInput from '../mdb/js/pro/chips';
import Animate from '../mdb/js/pro/animate';
import Sidenav from '../mdb/js/pro/sidenav';

export const mdb = {
  Alert,
  Button,
  Carousel,
  Collapse,
  Offcanvas,
  Dropdown,
  Input,
  Modal,
  Popover,
  Ripple,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip,
  Range,
  Navbar,
  ChipsInput,
  Animate,
  Sidenav,
};


window.document.addEventListener("DOMContentLoaded", function () {
  window.mdb = mdb;
  window.console.log("dom ready 1");
});
